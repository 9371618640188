.footer-wrapper {
    opacity: 100%;
    position: relative;

}
body {
    background-color: rgb(31, 0, 50);
}

.footer {
    display: flex;
    justify-content: center;
    color: #ffffff;
    padding: 20px 50px;
    font-family: "Inter", sans-serif;
    flex-wrap: wrap;
    padding-bottom: 100px;
    position: relative;
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 30px 80px;
    font-size: 20px;
}

.footer-column h2 {
    margin-bottom: 15px;
    color: #6d00fd;
    font-family: "Inter", sans-serif;
    font-weight: 800;
}

.footer-column p {
    margin: 5px 0;
    display: flex;
    margin-left: 25px;
    align-items: center;
    transition: transform 0.3s ease;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.footer-column a {
    color: #ffffff;
    text-decoration: none;
    margin-left: 23px;
}

.footer-column a:hover {
    text-decoration: none;
}

.footer-column p:hover {
    transform: scale(1.1);
}

.footer-column .bullet {
    font-size: 50px;
    margin: -12px;
    color: #7000FF;
}

.footer-logo-wrapper {
    position: relative;
    width: 350px;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./assets/img_1.png') no-repeat center center;
    background-size: cover;
    z-index: -1;
    border-radius: 50%;
    animation: rotate 120s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 50px;
    padding: 20px -5px;
    border-left: 5px solid #4300a2;
    border-right: 5px solid #4300a2;
    position: relative;
    z-index: 1;
}

.footer-logo img {
    width: 350px;
    height: 300px;
    position: relative;
}

.footer-disclaimer {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    bottom: 32px;
    padding-top: 4px;
    border-top: 5px solid #4200a1;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

.footer-disclaimer2 {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 15px;
    color: #ffffff;
    position: absolute;
    bottom: 15px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

.footer-disclaimer2 p {
    margin: -5px 0;
    margin-bottom: -3px;
}

.footer-disclaimer p {
    margin: 5px 0;
}

.footer-line {
    width: 100%;
    border: none;
    border-top: 6px solid #4200a1;
    margin: 20px 0;
    border-radius:10px;
}
