.voucher-activation-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    padding: 0px 45px 30px 45px;
    z-index: 1000;
}


.voucher-activation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.voucher-activation-content {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

.voucher-activation-content h2 {
    color: #a200e1;
    font-size: 35px;
}

.voucher-activation-content h3 {
    color: #a200e1;
    font-size: 25px;
}

.voucher-activation-content p {
    color: #ffffff;
    font-size: 20px;
}


.buttons {
    margin-top: 20px;
}

.buttons button {
    margin-right: 10px;
}

body {
    overflow: auto;
}

.modal-open {
    overflow: hidden;
}
