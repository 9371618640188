.ip-copy-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    padding: 0px 45px 30px 45px;
    z-index: 1000;
}

.ip-copy-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.ip-copy-content {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

.ip-copy-content h2 {
    color: #a200e1;
    font-size: 35px;
}

.button-close {
    width: 40%;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: #340051;
    color: #aa00ff;
    border-color: #54007e;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-close:hover {
    background-color: #2b0040;
    border-color: #580079;
    transform: scale(1.05);
}

body.modal-open {
    overflow: hidden;
}
