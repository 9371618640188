.home-wrapper {
    color: #ffffff;
    padding: 20px;
    overflow-x: hidden;
    font-family: "Inter", sans-serif;
}
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-family: "Inter", sans-serif;
}

.title2 {
    text-align: center;
    padding-top: 20px;
    font-weight: 900;
    font-size: 40px;
    color: #a200e1;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.home-line {
    width: 98%;
    border: none;
    border-top: 6px solid #4400a3;
    margin: 5px 0;
    border-radius: 10px;
}

.container-home-out{
    width: 80%;
    margin: 40px 10px 10px 10px;
    padding: 20px 60px 50px 60px;
    background: rgb(29, 0, 47);
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    position: relative;
}

.container-home-out3 {
    width: 50%;
    margin: 50px 10px 10px 10px;
    padding: 10px 30px 30px 30px;
    background: rgb(29, 0, 47);
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    position: relative;
}

@media (max-width: 768px) {
    .container-home-out, .container-home-out3 {
        width: 90%;
        padding: 20px 30px 40px 30px;
    }

    .trailer-content {
        flex-direction: column;
        align-items: center;
    }

    .team-members {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .container-home-out, .container-home-out3 {
        width: 100%;
        padding: 20px;
    }

    .team-members {
        grid-template-columns: 1fr;
    }
}

.server-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.container-home {
    max-width: 40%;
    align-items: center;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px 50px;
    margin: 20px;
}

@media (max-width: 768px) {
    .container-home {
        max-width: 90%;
        padding: 20px;
        margin: 10px 0;
    }

    .server-info {
        flex-direction: column;
        align-items: center;
    }
}

.container-home h2 {
    margin-top: 10px;
    margin-bottom: 25px;
    color: #6d00fd;
    font-size: 40px;
    font-family: "Inter", sans-serif;
    font-weight: 800;
}

.container-home-trailer {
    max-width: 40%;
    align-items: center;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px 50px;
    margin: 20px;
}

.text-content {
    max-width: 60%;
}

.image-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    height: auto;
}

.image-content img {
    max-width: 100%;
    width: 620px;
    height: auto;
    position: absolute;
}

.rotating-icon3 {
    animation: spin 200s linear infinite;
    top: -450px;
    right: 10px;
    width: 50%;
    height: auto;
}

.character-img {
    top: -410px;
    right: 50px;
    width: 50%;
    height: auto;
}

@media (max-width: 768px) {
    .image-content {
        width: 100%;
        height: auto;
    }

    .image-content img {
        transform: none;
        width: 80%;
        height: auto;
    }

    .rotating-icon3 {
        top: -35px;
        right: 5px;
    }

    .character-img {
        top: -20px;
        right: 25px;
    }
}

@media (max-width: 480px) {
    .image-content {
        width: 100%;
        height: auto;
    }

    .image-content img {
        width: 100%;
        height: auto;
    }

    .rotating-icon3 {
        top: -20px;
        right: 0;
    }

    .character-img {
        top: -10px;
        right: 0;
    }
}

.server-status-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.server-status2 {
    padding: 5px 15px;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 800;
    text-align: center;
    display: flex;
    align-items: center;
}

.status-indicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 8px;
}

.status-online {
    background-color: #00ff00;
}

.status-offline {
    background-color: red;
}

.trailer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
}

.trailer-info {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.team-members {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
    margin: 20px;
    margin-bottom: 10px;
    gap: 1rem;
}

.team-member {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
}

.team-member h3 {
    color: #c228ff;
    margin-bottom: 20px;
    font-size: 20px;
}
.team-member p {
    color: #e50000;
    margin-bottom: 10px;
    margin-top: -15px;
    padding: 5px;
    font-size: 15px;
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    background-color: #1f0034;
    border: 2px solid #350053;
    border-radius: 15px;
}

.team-member img {
    margin-bottom: -30px;
    width: 100px;
    height: 100px;
}

.button-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.button-home {
    width: 100%;
    padding: 15px;
    margin: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #340051;
    color: #aa00ff;
    border-color: #54007e;
    border-radius: 8px;
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 800;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-home2 {
    width: 100%;
    padding: 15px;
    margin: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #340051;
    color: #fa7d00;
    border-color: #54007e;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 800;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-home-download {
    width: 50%;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 10px;
    background-color: #340051;
    color: #aa00ff;
    border-color: #54007e;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 800;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-home2:hover,
.button-home-download:hover,
.button-home:hover {
    background-color: #2b0040;
    border-color: #580079;
    transform: scale(1.05);
}
