.voucher-wrapper {
    color: #ffffff;
    padding: 20px;
    opacity: 100%;
    overflow-x: hidden;
}
.voucher {
    display: flex;
    justify-content: center;
    color: #ffffff;
    padding: 10px 28px;
    font-family: "Inter", sans-serif;
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}

.title2 {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    color: #a200e1;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.subtitle {
    text-align: center;
    font-size: 23px;
    font-weight: 1000;
    color: #9300ff;
    margin: 20px 0 10px;
}

.voucher-line {
    width: 100%;
    border: none;
    border-top: 6px solid #4400a3;
    margin: 5px 0;
    border-radius: 10px;
}

.voucher-line2 {
    width: 100%;
    border: none;
    border-top: 3px solid #4400a3;
    margin: 15px;
    margin-top: -10px;
    border-radius: 10px;
}

.container-voucher {
    max-width: 700px;
    margin: 40px 10px 10px 10px;
    padding: 20px 300px 20px 300px;
    background-color: #1f0034;
    color: #fff;
    border: 2px solid #350053;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Inter", sans-serif;
}

.container-voucher-out {
    margin: 40px 10px 10px 10px;
    padding: 20px 60px 50px 60px;
    background: rgb(29, 0, 47);
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Inter", sans-serif;
}

.form-row2 {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 20px;
}

.form-group2 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.form-group2 input[type="text"] {
    text-align: center;
    width: 100%;
    padding: 15px;
    margin-top: -2px;
    border: 2px solid rgba(143, 131, 124, 0.43);
    border-radius: 8px;
    background-color: #340051;
    color: #fdfdfd;
    border-color: #54007e;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
}

.form-group2 input[type="text"]:focus {
    background-color: #2b0040;
    border-color: #580079;
}

.button-voucher {
    width: 100%;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #340051;
    color: #aa00ff;
    border-color: #54007e;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: inset 0 0 15px 4px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-voucher:hover {
    background-color: #2b0040;
    border-color: #580079;
    transform: scale(1.05);
}


.button-voucher:disabled, button.disabled {
    background-color: #210131;
    cursor: not-allowed;
    transform: none;
    color: rgb(64, 0, 86);

}